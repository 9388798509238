.custom-table-row:nth-child(even) {
  .ant-row {
    margin-bottom: 0px;
  }
  background: #fafafa;
}

.custom-table-row {
  .ant-row {
    margin-bottom: 0px;
  }
}
