.custom-header {
  background-color: white;
}

.custom-config {
  background-color: white;
}

.custom-nesting-table {
  margin-bottom: 20;
  margin-top: 5;
}

.ant-table-expanded-row-level-1 {
  background-color: 'black' !important;
}

