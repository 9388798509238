.ag-theme-material .ag-ltr .ag-details-row {
  padding: 0 !important;
  width: 100%;
}
.area-percent {
  height: 10%;
}
.text-bold {
  font-weight: 500;
}
.paddingLeft-40 {
  padding-left: 40px !important;
  // margin-left: 20px !important;
}
.paddingLeft-60 {
  padding-left: 40px !important;
  // margin-left: 20px !important;
}
