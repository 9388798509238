.ag-details-row {
  padding: 30px !important;
}

.card-statistic {
  box-shadow: 5 10px 18px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
    0 12px 48px 16px rgba(0, 0, 0, 0.03);
  height: 57px;
}

.ant-card-body {
  padding: 10px;
  height: inherit;
}

.ag-theme-material {
  height: 578px;
}
