@import './variables/antd.variables.less';

::-webkit-scrollbar {
  display: none;
}

// a:hover {
//   cursor: pointer;
//   text-decoration: underline;
// }
// a {
//   color: #1890ff !important;
// }

@media (min-width: @screen-sm-min) {
  .show-mobile-only {
    display: none;
  }
}
@media (max-width: @screen-sm) {
  .show-desktop-only {
    display: none;
  }
}
.booking-campaign {
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.ant-layout {
  .ant-layout-sider {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 2;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  }

  .ant-layout-header {
    position: sticky;
    padding: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }
}

@media (min-width: 576px) {
  .ant-layout .ant-layout-header span[aria-label='menu-unfold'] {
    display: none;
  }
}

.ant-layout-sider-trigger {
  background: #fff !important;
  border-top: 1px solid #d9d9d9;
  color: #002140 !important;
}

.ant-layout-sider-zero-width-trigger {
  // background: #737373 !important;
  background: red !important;
}

.basic-layout {
  transform: rotate(0);
  transition: all 0.2s;
}

.row-expandeble {
  font-weight: bold !important;
  // background-color: rgb(250, 250, 250) !important;
}

.ant-card.card-filter {
  margin-bottom: 12px;

  .ant-card-body {
    padding-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-card.card-statistic-sm .ant-statistic {
  .ant-statistic-title {
    font-size: inherit;
  }

  .ant-statistic-content {
    font-size: 16px;
  }
}

.ant-tabs .ant-tabs-content {
  height: 100%;
}

.readonly-white {
  background-color: #fff !important;
  color: #333 !important;
  .ant-input[disabled],
  .ant-input-number-input {
    background-color: #fff !important;
    color: #333;
  }
}

p.title {
  color: #333333 !important;
  font-size: 14px !important;
  font-weight: bold;
  font-variant: tabular-nums !important;
  line-height: 1.5;
  padding: 16px;
}

ul.scroll {
  white-space: nowrap;
  overflow-x: scroll;
}
// ul.scroll::-webkit-scrollbar {
//   display: none;
// }

ul.scroll > li.item {
  display: inline-block;
  margin-right: 16px;
  min-width: 250px;
}
ul.scroll > li.item:last-child {
  margin-right: 0px;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.ant-empty-image svg {
  height: 100%;
  width: 100%;
  margin: auto;
}

.ant-input-number {
  width: 100% !important;
}
