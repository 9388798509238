.custom-checkbox {
  .ant-checkbox:hover {
    border: none;
  }
  .ant-checkbox-checked:hover {
    border: none;
  }
  .ant-checkbox-checked::after {
    border: none !important;
  }
}

.custom-checkbox:hover {
  background-color: rgba(238, 237, 237, 0.952);
}
